import sortBy from 'lodash/sortBy';
import {
  GQFilterFieldLegacy,
  GQFilterOperator,
} from '@watershed/shared-universal/generated/graphql-schema-types';
import { displayableAttributeValue } from './analytics';
import assertNever from '@watershed/shared-util/assertNever';
import { formatList } from '@watershed/intl/formatters';
import { i18n } from '@watershed/intl';
interface FilterPrimitive {
  field: string;
  operator: GQFilterOperator;
  value: ReadonlyArray<string>;
}

export interface Options {
  includeFieldNames: boolean;
  lowerCase: boolean;
  hideGhgCategoryNames: boolean;
  separator: string;
  sort?: boolean;
  excludeGhgScopeAndCategory?: boolean;
}

function filterOperatorToString(operator: GQFilterOperator) {
  switch (operator) {
    case GQFilterOperator.In:
      return { verb: 'is', conjunction: 'or' };
    case GQFilterOperator.NotIn:
      return { verb: 'is not', conjunction: 'nor' };
    default:
      assertNever(operator);
  }
}

function ghgScopeFilterToString(scopeFilter: FilterPrimitive, idx: number) {
  const prefix = idx === 0 ? 'Scope' : 'scope';
  return `${prefix} ${formatList(
    sortBy(scopeFilter.value.map((val) => val.charAt(val.length - 1))),
    { type: 'conjunction', locale: i18n.locale }
  )}`;
}

function ghgCategoryFilterToString(
  categoryFilter: FilterPrimitive,
  idx: number,
  { hideGhgCategoryNames = false }: Pick<Options, 'hideGhgCategoryNames'>
) {
  const prefix = idx === 0 ? 'Scope' : 'scope';
  const values = categoryFilter.value.map((val) => {
    if (hideGhgCategoryNames) {
      const match = val.match(/(3\.\d\d?) [A-Za-z ]+/);
      return match && match[1] ? match[1] : val;
    }
    if (val === '1 Scope 1') {
      return '1';
    }
    if (val === '2 Scope 2') {
      return '2';
    }
    return val;
  });
  return `${prefix} ${formatList(sortBy(values), {
    type: 'conjunction',
    locale: i18n.locale,
  })}`;
}

function otherFilterToString(
  filt: FilterPrimitive,
  idx: number,
  { includeFieldNames, lowerCase }: Options
) {
  if (includeFieldNames) {
    const field = displayableAttributeValue(
      filt.field,
      idx !== 0 || lowerCase ? 'MidSentence' : 'StartSentence'
    );
    const { verb } = filterOperatorToString(filt.operator);
    const valuesText = formatList(
      filt.value.map((v) => displayableAttributeValue(v, 'MidSentence')),
      {
        type: GQFilterOperator.In ? 'conjunction' : 'disjunction',
        locale: i18n.locale,
        style: 'long',
      }
    );
    return `${field} ${verb} ${valuesText}`;
  } else {
    const values = filt.value.map((v, valueIdx) =>
      // Don't change capitalization on vendor name
      filt.field === 'vendor'
        ? v
        : displayableAttributeValue(
            v,
            idx !== 0 || valueIdx !== 0 || lowerCase
              ? 'MidSentence'
              : 'StartSentence'
          )
    );
    return formatList(values, { type: 'conjunction', locale: i18n.locale });
  }
}

function isGhgScopeFilter(filt: FilterPrimitive) {
  return filt.field === GQFilterFieldLegacy.GhgScope;
}
function isGhgCategoryFilter(filt: FilterPrimitive) {
  return filt.field === GQFilterFieldLegacy.GhgCategoryId;
}

function filterToString(filt: FilterPrimitive, idx: number, options: Options) {
  if (isGhgScopeFilter(filt)) {
    return ghgScopeFilterToString(filt, idx);
  } else if (isGhgCategoryFilter(filt)) {
    return ghgCategoryFilterToString(filt, idx, options);
  }
  return otherFilterToString(filt, idx, options);
}

function maybeSortFilters(
  filters: Array<FilterPrimitive>,
  shouldSort: boolean
) {
  if (!shouldSort) return filters;

  return filters.sort().map((f) => {
    return {
      ...f,
      value: f.value.slice(0).sort(),
    };
  });
}

export function filterToHumanReadableStringComponent(
  filters: ReadonlyArray<FilterPrimitive>,
  {
    includeFieldNames = true,
    lowerCase = false,
    hideGhgCategoryNames = false,
    separator = ', ',
    sort = false,
    excludeGhgScopeAndCategory = false,
  }: Partial<Options> = {}
): Array<string> {
  const scopeFilters = !excludeGhgScopeAndCategory
    ? maybeSortFilters(filters.filter(isGhgScopeFilter), sort)
    : [];
  const ghgCategoryFilters = !excludeGhgScopeAndCategory
    ? maybeSortFilters(filters.filter(isGhgCategoryFilter), sort)
    : [];
  const otherFilters = maybeSortFilters(
    filters.filter(
      (filt) => !(isGhgScopeFilter(filt) || isGhgCategoryFilter(filt))
    ),
    sort
  );

  return [
    // GHG scope filters are redundant if GHG category filters are present
    ...(ghgCategoryFilters.length ? [] : scopeFilters),
    ...ghgCategoryFilters,
    ...otherFilters,
  ].map((filter, index) =>
    filterToString(filter, index, {
      includeFieldNames,
      lowerCase,
      hideGhgCategoryNames,
      separator,
    })
  );
}

export default function filterToHumanReadableString(
  filters: ReadonlyArray<FilterPrimitive>,
  options: Partial<Options> = {}
): string {
  return filterToHumanReadableStringComponent(filters, options).join(
    options.separator ?? ', '
  );
}
