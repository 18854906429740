import { gql } from 'graphql-tag';
import { PermissionType } from '@watershed/constants/permissions';

import { GQReportMode } from '@watershed/app-dashboard/generated/graphql-schema-types';

import flattenConnection from '@watershed/shared-universal/utils/flattenConnection';

import useHasPermission from '../../utils/useHasPermission';
import { useReportSummariesQuery } from '@watershed/app-dashboard/generated/urql';
import {
  WatershedProduct,
  useCurrentProductContext,
} from '../../utils/CurrentProductContext';

gql`
  fragment ReportSummary on Report {
    id
    name
    updatedAt
  }

  query ReportSummaries($isAdmin: Boolean!, $reportMode: ReportMode!) @withOwner(owner: Reporting) {
    formalReports(last: 5, reportMode: $reportMode) {
      edges {
        node {
          ...ReportSummary
        }
      }
    }
  }
`;

export function useRecentReportSummaries() {
  const isAdmin = useHasPermission([PermissionType.Admin]);
  const { currentProduct } = useCurrentProductContext();

  const [{ data, fetching: isLoading }] = useReportSummariesQuery({
    variables: {
      isAdmin,
      reportMode:
        currentProduct === WatershedProduct.Finance
          ? GQReportMode.Finance
          : GQReportMode.Corporate,
    },
    pause: currentProduct === WatershedProduct.Unknown,
  });

  const reports = flattenConnection(data?.formalReports) || [];
  // sorting is returned from the server
  const recentReports = reports.slice(0, 5);

  return { recentReports, isLoading };
}
