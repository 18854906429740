import { useLingui } from '@lingui/react/macro';
import { GQCreatePermissionItemInput } from '@watershed/app-dashboard/generated/graphql-schema-types';

import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { UnexpectedError } from '@watershed/errors/UnexpectedError';
import useLocale from '@watershed/intl/frontend/useLocale';
import useSnackbar from '@watershed/shared-frontend/hooks/useSnackbar';
import { formatObjectName } from '@watershed/shared-universal/permissions/AddUserPermissionsUtils';
import { VALUE_MAPPING_OBJECT_NAME } from '@watershed/shared-universal/permissions/permissionUtils';
import {
  TextFieldMultiline,
  TextFieldMultilineNonFormik,
} from '@watershed/ui-core/components/Form/TextField';
import useDebounce from '@watershed/ui-core/hooks/useDebounce';
import invariant from 'invariant';
import { ReactNode, useState } from 'react';
import { gql } from 'urql';
import {
  useAddPermissionMutation,
  useTypeaheadUserQuery,
} from '@watershed/app-dashboard/generated/urql';
import enqueueMutationSnackbar from '@watershed/shared-frontend/utils/enqueueMutationSnackbar';
import ConfirmPermissionDialog from '@watershed/shared-frontend/components/ConfirmPermissionDialog';

const DEBOUNCE_DELAY = 200;

gql`
  fragment UserSearchResult on User {
    id
    name
  }

  # eslint-disable-next-line @watershed/gql-operations-must-assign-owners
  query TypeaheadUser(
    $search: String!
  ) {
    usersFuzzySearch(
      q: $search
    ) {
      ...UserSearchResult
    }
  }
`;

export function MentionsPopover({
  users,
  anchorEl,
  onClose,
  onClick,
  setAnchorEl,
  handleKeyDown,
}: {
  users: Array<MentionedUser>;
  anchorEl?: HTMLTextAreaElement | null;
  onClose: () => void;
  onClick: (user: MentionedUser) => void;
  setAnchorEl: (anchorEl: HTMLTextAreaElement | null) => void;
  handleKeyDown: (event: React.KeyboardEvent<HTMLLIElement>) => void;
}) {
  if (users.length === 0) {
    onClose();
  }

  return (
    <Menu
      open={anchorEl !== null}
      anchorEl={anchorEl}
      transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      PaperProps={{
        style: {
          transform: `translateY(-9px)`, // Move the menu up
          minWidth: '500px',
          maxHeight: '400px',
        },
      }}
      onClose={onClose}
    >
      {users.map((user) => (
        <MenuItem
          key={user.id}
          onClick={(e) => {
            onClick(user);
            setAnchorEl(null);
          }}
          onKeyDown={(e) => handleKeyDown(e)}
          onKeyUp={(e) => {
            if (e.key === ' ') {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        >
          <ListItemText primary={user.name} />
        </MenuItem>
      ))}
    </Menu>
  );
}

export interface WithMentionsProps {
  setMessageText: (value: string) => void;
  onAddMentionedUser: (user: MentionedUser) => void;
  message: string;
  children(props: WithMentionsRenderProps): ReactNode;
}

export interface WithMentionsAndPermissionsProps extends WithMentionsProps {
  userDataById: Record<
    string,
    {
      id: string;
      name: string;
      hasPermissions: boolean;
      hasAnyPermissions: boolean;
      orgAccessId: string;
    }
  >;
  permissions?: Array<GQCreatePermissionItemInput & { objectName?: string }>;
  permissionCheck: 'hasAnyPermission' | 'hasEveryPermission';
}

export function WithMentions({
  setMessageText,
  onAddMentionedUser,
  message,
  children,
}: WithMentionsProps) {
  const [mentionText, setMentionText] = useState('');
  const [mentionMode, setMentionMode] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLTextAreaElement | null>(null);
  const [textBeforeMention, setTextBeforeMention] = useState('');
  const [textAfterMention, setTextAfterMention] = useState('');
  const debouncedSearchTerm = useDebounce(mentionText, DEBOUNCE_DELAY);

  const [res] = useTypeaheadUserQuery({
    variables: {
      search: debouncedSearchTerm,
    },
    pause: !mentionMode,
  });

  const handleSelectUserMention = async (user: MentionedUser) => {
    setMentionMode(false);
    const newText =
      textBeforeMention + '@' + user.name + ' ' + textAfterMention;
    const cursorIndex = textBeforeMention.length + user.name.length + 1;
    setMentionText('');
    setMessageText(newText);
    onAddMentionedUser(user);
    setTimeout(() => {
      anchorEl?.setSelectionRange(cursorIndex, cursorIndex);
    }, 0);
  };

  const handleCloseMentionsPopover = async (value: string) => {
    setMentionMode(false);
    setMentionText('');
    setAnchorEl(null);
    setMessageText(value);
  };

  return (
    <>
      {children({
        value: message,
        setValue: setMessageText,
        onKeyDown: (e) => {
          const target = e.target;
          if (target instanceof HTMLTextAreaElement) {
            if (e.key === '@') {
              const mentionStartIndex = target.selectionStart;
              setMentionMode(true);
              setTextBeforeMention(message.substring(0, mentionStartIndex));
              setTextAfterMention(message.substring(mentionStartIndex));
            }
          }
        },
        onKeyUp: (e) => {
          if (mentionMode) {
            setAnchorEl(e.target as HTMLTextAreaElement);
          }
        },
      })}
      {mentionMode && res.data && (
        <MentionsPopover
          users={res.data?.usersFuzzySearch || []}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          onClose={async () => {
            await handleCloseMentionsPopover(
              textBeforeMention + '@' + mentionText + textAfterMention
            );
          }}
          onClick={async (user) => {
            await handleSelectUserMention(user);
          }}
          handleKeyDown={async (event) => {
            const key = event.key;
            if (key === 'Backspace' && mentionText === '') {
              await handleCloseMentionsPopover(
                textBeforeMention + textAfterMention
              );
            } else if (
              !event.ctrlKey &&
              !event.altKey &&
              !event.metaKey &&
              key.length === 1
            ) {
              setMessageText(
                textBeforeMention + '@' + mentionText + key + textAfterMention
              );
              setMentionText(mentionText + key);
            } else if (key === 'Backspace') {
              setMessageText(
                textBeforeMention +
                  '@' +
                  mentionText.slice(0, -1) +
                  textAfterMention
              );
              setMentionText(mentionText.slice(0, -1));
            }
          }}
        />
      )}
    </>
  );
}

export type MentionedUser = {
  id: string;
  name: string;
};

export function WithMentionsAndPermissions({
  userDataById,
  setMessageText,
  permissions,
  onAddMentionedUser,
  message,
  children,
  permissionCheck = 'hasEveryPermission',
}: WithMentionsAndPermissionsProps) {
  const locale = useLocale();
  const { t } = useLingui();
  const snackbar = useSnackbar();
  const [userToConfirmPermissionsFor, setUserToConfirmPermissionsFor] =
    useState<MentionedUser | null>(null);
  const [, executeAddPermission] = useAddPermissionMutation();
  const [usersGrantedPermissions, setUsersGrantedPermissions] = useState<
    Array<MentionedUser>
  >([]);

  const permissionsOptions = permissions ? [permissions] : [[]];

  const handleConfirmPermissions = async (
    permissions: Array<GQCreatePermissionItemInput & { objectName?: string }>
  ) => {
    invariant(
      userToConfirmPermissionsFor,
      'must have one user with insufficient permissions'
    );
    // TODO: Potentially allow people to assign ApproveDatasource permissions here
    const permissionToAssign =
      permissions[0] ??
      permissions.find((p) => p.permission === 'ManageDatasource');

    invariant(permissionToAssign, 'must have one permission to assign');

    const result = await executeAddPermission({
      input: {
        orgAccessId: userDataById[userToConfirmPermissionsFor.id].orgAccessId,
        permission: permissionToAssign.permission,
        objectId: permissionToAssign.objectId,
        objectType: permissionToAssign.objectType,
      },
    });

    void enqueueMutationSnackbar(snackbar, result, {
      onSuccess: () => {
        setUsersGrantedPermissions([
          ...usersGrantedPermissions,
          userToConfirmPermissionsFor,
        ]);
        setUserToConfirmPermissionsFor(null);
        // Re-trigger the mention after permissions are granted
        const user = userToConfirmPermissionsFor;
        if (user) {
          onAddMentionedUser(user);
        }
      },
    });
  };

  const objectNames = new Set(permissions?.map((p) => p.objectName));
  UnexpectedError.invariant(
    !permissions || objectNames.size === 1,
    'Expected only one object name for DataIssueDialog permissions'
  );
  const objectName = objectNames.values().next().value;
  const formattedObjectName = formatObjectName(locale, objectName);
  const name = userToConfirmPermissionsFor?.name;
  const permissionsText = name
    ? objectName === VALUE_MAPPING_OBJECT_NAME
      ? t`${name} doesn't have access to this discussion. To add them, you'll need to grant them higher level permissions that enable them to view financial mapping data. Would you like to grant permission to manage measurements?`
      : t`${name} doesn't have access to this discussion. To add them, you'll need to grant access to upload and view summaries for the ${formattedObjectName} data source only.`
    : null;

  const handleUserSelectPermissionsCheck = async (user: MentionedUser) => {
    if (
      !usersGrantedPermissions.some((u) => u.id === user.id) &&
      !(permissionCheck === 'hasAnyPermission'
        ? userDataById[user.id].hasAnyPermissions
        : userDataById[user.id].hasPermissions)
    ) {
      setUserToConfirmPermissionsFor(user);
      return false;
    }
    return true;
  };

  return (
    <>
      <WithMentions
        setMessageText={setMessageText}
        onAddMentionedUser={async (mentionedUser) => {
          const user = userDataById[mentionedUser.id];
          if (!user) {
            return;
          }
          if (await handleUserSelectPermissionsCheck(user)) {
            onAddMentionedUser(mentionedUser);
          }
        }}
        message={message}
      >
        {children}
      </WithMentions>
      {userToConfirmPermissionsFor && (
        <ConfirmPermissionDialog
          onConfirm={async (permissions) => {
            await handleConfirmPermissions(permissions);
          }}
          onCancel={() => {
            setUserToConfirmPermissionsFor(null);
          }}
          permissionsOptions={permissionsOptions}
          shouldShowPermissions={false}
        >
          {permissionsText}
        </ConfirmPermissionDialog>
      )}
    </>
  );
}

interface WithMentionsRenderProps {
  value: string;
  setValue: (value: string) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onKeyUp: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

type TextFieldMultilineForMentionsProps = Omit<
  WithMentionsProps,
  'children'
> & {
  id: string;
  rows: number;
  maxLength?: number;
  required?: boolean;
  placeholder?: string;
  'data-testid': string;
  label?: ReactNode;
  style?: React.CSSProperties;
};

type TextFieldMultilineForMentionsWithPermissionsProps = Omit<
  WithMentionsAndPermissionsProps,
  'children'
> & {
  id: string;
  rows: number;
  placeholder?: string;
  'data-testid': string;
  label?: ReactNode;
};

export function TextFieldMultilineForMentions(
  props: TextFieldMultilineForMentionsProps
) {
  return (
    <WithMentions {...props}>
      {({ onKeyDown, onKeyUp }) => (
        <TextFieldMultiline
          value={props.message}
          onChange={(e) => props.setMessageText(e.target.value)}
          {...props}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
        />
      )}
    </WithMentions>
  );
}

export function TextFieldMultilineForMentionsWithPermissionsNonFormik(
  props: TextFieldMultilineForMentionsWithPermissionsProps
) {
  return (
    <WithMentionsAndPermissions {...props}>
      {({ onKeyDown, onKeyUp }) => (
        <TextFieldMultilineNonFormik
          value={props.message}
          onChange={(event) => props.setMessageText(event.target.value)}
          {...props}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
        />
      )}
    </WithMentionsAndPermissions>
  );
}
