import invariant from 'invariant';
import { FilterConjunction } from '@watershed/constants/filter';

import { DisclosureTargetIntensityType } from '@watershed/constants/reductions';

import last from 'lodash/last';
import sortBy from 'lodash/sortBy';
import { getUnitTooltipForGQAggregateKind } from '../analysis/AnalysisUtils';
import { SurveyAnswer } from '../companySurveys/types';
import { msg } from '@lingui/core/macro';
import {
  GQAggregateKind,
  GQCdpVendorData,
  GQCompanyEmissionsUnits,
  GQDisclosureQualityScore,
  GQEmissionsSource,
  GQFilterFieldLegacy,
  GQPrivateDisclosureType,
  GQExternalReportType,
  GQFilterOperator,
} from '@watershed/shared-universal/generated/graphql-schema-types';
import assertNever from '@watershed/shared-util/assertNever';
import { getAllScopeEmissionsAndRatios } from './companyUtils';
import { CurrencyCode } from '@watershed/constants/currency';
import isNullish from '@watershed/shared-util/isNullish';
import isNotNullish from '@watershed/shared-util/isNotNullish';
import { surveyAnswersHaveSomeEmissionsData } from '../companySurveys/utils';
import {
  ALL_GHG_IDS,
  SCOPE_1_CATEGORY_ID,
  SCOPE_2_CATEGORY_ID,
  SCOPE_3_1_ID,
  SCOPE_3_10_ID,
  SCOPE_3_11_ID,
  SCOPE_3_12_ID,
  SCOPE_3_13_ID,
  SCOPE_3_14_ID,
  SCOPE_3_15_ID,
  SCOPE_3_2_ID,
  SCOPE_3_3_ID,
  SCOPE_3_4_ID,
  SCOPE_3_5_ID,
  SCOPE_3_6_ID,
  SCOPE_3_7_ID,
  SCOPE_3_8_ID,
  SCOPE_3_9_ID,
} from '../constants';
import must from './must';
import uniq from 'lodash/uniq';
import { YM, YearMonth } from './YearMonth';

import { getCurrencySymbol } from './currencyUtils';
import { formatNumberMagnitude } from './helpers';
import { DateTime } from 'luxon';
import orderBy from 'lodash/orderBy';
import DateTimeUtils from './DateTimeUtils';
import isEmpty from 'lodash/isEmpty';
import { t } from '@lingui/core/macro';
import { SupportedLocale } from '@watershed/intl/constants';
import { MessageDescriptor } from '@lingui/core';
import { i18n } from '@watershed/intl';
import { formatList } from '@watershed/intl/formatters';
import groupBy from 'lodash/groupBy';
import {
  SupplierDisclosureTargetField,
  SupplierForReductionsForecast,
  SupplierHistoricalEmissionsFields,
} from '../forecast/types';
import { GQSupplierHistoricalEmissions } from '@watershed/shared-universal/generated/graphql-schema-types';
import EmissionsYear from '../companyData/EmissionsYear';

export type DisclosureQualityScoreInput = Pick<
  GQCdpVendorData,
  | 'totalEmissionsNonzero'
  | 'scope1Nonzero'
  | 'scope2Nonzero'
  | 'scope3Nonzero'
  | 'scope301Or302Nonzero'
  | 'scope1Verified'
  | 'scope2Verified'
  | 'scope3Verified'
  | 'pctEvaluationStatusesMatchResponse'
  | 'disclosureQualityScore'
>;

export interface DisclosureEFQualityInput {
  disclosedScope1: boolean;
  disclosedScope2: boolean;
  disclosedScope3: boolean;
  disclosedRevenue: boolean;
  disclosedCEEActivities: boolean;
  disclosedCleanPower: boolean;
  thirdPartyVerified: boolean;
  scope3CategoryBreakdown: boolean;
  disclosedIndustry: string;
  disclosedCountries: boolean;
  explanations: Array<string>;
  improvements: Array<string>;
}

type DisclosureTargetFilters = SupplierDisclosureTargetField['filters'];

export const DISCLOSURE_QUALITY_SCORE_LABELS: Record<
  GQDisclosureQualityScore,
  MessageDescriptor
> = {
  [GQDisclosureQualityScore.VeryHigh]: msg({ message: 'Very high confidence' }),
  [GQDisclosureQualityScore.High]: msg({ message: 'High confidence' }),
  [GQDisclosureQualityScore.Medium]: msg({ message: 'Medium confidence' }),
  [GQDisclosureQualityScore.Low]: msg({ message: 'Low confidence' }),
  [GQDisclosureQualityScore.Unusable]: msg({ message: `Very low confidence` }),
};
export const DISCLOSURE_QUALITY_SCORE_COLORS: Record<
  GQDisclosureQualityScore,
  'success' | 'warning' | 'error'
> = {
  [GQDisclosureQualityScore.VeryHigh]: 'success',
  [GQDisclosureQualityScore.High]: 'success',
  [GQDisclosureQualityScore.Medium]: 'warning',
  [GQDisclosureQualityScore.Low]: 'error',
  [GQDisclosureQualityScore.Unusable]: 'error',
};

export function getDisclosureQualityScoreTitle(
  disclosureQualityScore: GQDisclosureQualityScore
): string {
  const qualityScore = i18n._(
    DISCLOSURE_QUALITY_SCORE_LABELS[disclosureQualityScore]
  );
  return t({
    message: `Watershed has ${qualityScore} that this disclosure is complete and of reasonable quality.`,
    context:
      'Message displaying the quality score example: Watershed has very high condifence... ',
  });
}

function getDisclosedEmissionsString(
  disclosureEmissionsData: DisclosureQualityScoreInput
) {
  if (!disclosureEmissionsData.totalEmissionsNonzero) {
    return t`It does not disclose any emissions data.`;
  }

  const disclosedEmissionScopes = [];
  const undisclosedEmissionsScopes = [];
  if (disclosureEmissionsData.scope1Nonzero) {
    disclosedEmissionScopes.push(t`Scope 1`);
  } else {
    undisclosedEmissionsScopes.push(t`Scope 1`);
  }
  if (disclosureEmissionsData.scope2Nonzero) {
    disclosedEmissionScopes.push(t`Scope 2`);
  } else {
    undisclosedEmissionsScopes.push(t`Scope 2`);
  }

  if (disclosureEmissionsData.scope3Nonzero) {
    disclosedEmissionScopes.push(`Scope 3`);
  } else {
    undisclosedEmissionsScopes.push(`Scope 3`);
  }
  const locale = i18n.locale as SupportedLocale;

  const disclosedEmissionScopesString = formatList(disclosedEmissionScopes, {
    type: 'conjunction',
    style: 'long',
    locale,
  });

  const undisclosedEmissionsString = formatList(undisclosedEmissionsScopes, {
    type: 'conjunction',
    style: 'long',
    locale,
  });

  const combinedString =
    undisclosedEmissionsScopes.length === 0
      ? t({
          context:
            'example: Disclosed emissions: Scope 1, Scope 2, Scope 3 emissions.',
          message: `Disclosed emissions include: ${disclosedEmissionScopesString} emissions.`,
        })
      : t({
          message: `Disclosed emissions include: ${disclosedEmissionScopesString} emissions, and undisclosed emissions include: ${undisclosedEmissionsString} emissions.`,
          context:
            'example: Disclosed emissions include: Scope 1, Scope 2 emissions, and undisclosed emissions include: Scope 3 emissions.',
        });

  return combinedString;
}

function getDisclosureUnusableQualityExplanation(
  disclosureEmissionsData: DisclosureQualityScoreInput
) {
  invariant(
    disclosureEmissionsData.disclosureQualityScore ===
      GQDisclosureQualityScore.Unusable,
    'Expected disclosureQualityScore to be Unusable'
  );

  return getDisclosedEmissionsString(disclosureEmissionsData);
}

function getDisclosureVeryHighQualityExplanation(
  disclosureEmissionsData: DisclosureQualityScoreInput
) {
  invariant(
    disclosureEmissionsData.disclosureQualityScore ===
      GQDisclosureQualityScore.VeryHigh,
    'Expected disclosureQualityScore to be VeryHigh'
  );

  const disclosedEmissionsString = getDisclosedEmissionsString(
    disclosureEmissionsData
  );

  return (
    `${disclosedEmissionsString} ` +
    t`An explanation is provided for all upstream scope 3 emissions that are not disclosed. This disclosure's emissions were verified by a third-party.`
  );
}

function getDisclosureHighQualityExplanation(
  disclosureEmissionsData: DisclosureQualityScoreInput
) {
  invariant(
    disclosureEmissionsData.disclosureQualityScore ===
      GQDisclosureQualityScore.High,
    'Expected disclosureQualityScore to be High'
  );

  const disclosedEmissionsString = getDisclosedEmissionsString(
    disclosureEmissionsData
  );

  return (
    `${disclosedEmissionsString} ` +
    t`An explanation is provided for all upstream scope 3 emissions that are not disclosed.`
  );
}

function getDisclosureMediumQualityExplanation(
  disclosureEmissionsData: DisclosureQualityScoreInput
) {
  invariant(
    disclosureEmissionsData.disclosureQualityScore ===
      GQDisclosureQualityScore.Medium,
    'Expected disclosureQualityScore to be Medium'
  );

  return `${getDisclosedEmissionsString(disclosureEmissionsData)} ${
    !(
      disclosureEmissionsData.scope1Verified ||
        disclosureEmissionsData.scope2Verified ||
        disclosureEmissionsData.scope3Verified
    )
      ? t`Disclosed emissions were not reported as verified nor assured by a third-party.`
      : ''
  } ${
    !(disclosureEmissionsData.pctEvaluationStatusesMatchResponse !== 1)
      ? t`An explanation is not provided for some upstream scope 3 emissions that are not disclosed.`
      : ''
  }`;
}

function getDisclosureLowQualityExplanation(
  disclosureEmissionsData: DisclosureQualityScoreInput
) {
  invariant(
    disclosureEmissionsData.disclosureQualityScore ===
      GQDisclosureQualityScore.Low,
    'Expected disclosureQualityScore to be Low'
  );

  return `${getDisclosedEmissionsString(disclosureEmissionsData)} ${
    !disclosureEmissionsData.scope301Or302Nonzero
      ? t`However, it does not disclose emissions from either capital goods and purchased goods and services.`
      : ''
  } ${
    !(
      disclosureEmissionsData.scope1Verified ||
        disclosureEmissionsData.scope2Verified ||
        disclosureEmissionsData.scope3Verified
    )
      ? t`Disclosed emissions were not reported as verified nor assured by a third-party.`
      : ''
  } ${
    !(disclosureEmissionsData.pctEvaluationStatusesMatchResponse !== 1)
      ? t`An explanation is not provided for some upstream scope 3 emissions that are not disclosed.`
      : ''
  }`;
}

export function getDisclosureQualityExplanation(
  disclosureEmissionsData: DisclosureQualityScoreInput
): string {
  switch (disclosureEmissionsData.disclosureQualityScore) {
    case GQDisclosureQualityScore.Unusable:
      return getDisclosureUnusableQualityExplanation(disclosureEmissionsData);
    case GQDisclosureQualityScore.VeryHigh:
      return getDisclosureVeryHighQualityExplanation(disclosureEmissionsData);
    case GQDisclosureQualityScore.High:
      return getDisclosureHighQualityExplanation(disclosureEmissionsData);
    case GQDisclosureQualityScore.Medium:
      return getDisclosureMediumQualityExplanation(disclosureEmissionsData);
    case GQDisclosureQualityScore.Low:
      return getDisclosureLowQualityExplanation(disclosureEmissionsData);
    default:
      assertNever(disclosureEmissionsData.disclosureQualityScore);
  }
}

export function getDisclosureEFExplanations(
  {
    disclosedScope1,
    disclosedScope2,
    disclosedScope3,
    disclosedRevenue,
    disclosedCleanPower,
    disclosedCEEActivities,
    scope3CategoryBreakdown,
    thirdPartyVerified,
    disclosedIndustry,
    disclosedCountries,
  }: Omit<DisclosureEFQualityInput, 'explanations' | 'improvements'>,
  supplierName: string
): { explanations: Array<string>; improvements: Array<string> } {
  const disclosedScopes123 =
    disclosedScope1 && disclosedScope2 && disclosedScope3;
  const disclosedAnyEmissions =
    disclosedScope1 || disclosedScope2 || disclosedScope3;

  const explanations: Array<string> = [];
  const improvements: Array<string> = [];

  const shouldThirdPartyVerifyImprovement = t`${supplierName} should have their footprint verified by a third-party to ensure correctness.`;
  const shouldMeasureScopes123Improvement = t`${supplierName} should measure and disclose their Scope 1, Scope 2, and upstream Scope 3 emissions per unit of revenue (revenue intensity).`;
  const shouldDiscloseCleanPowerImprovement = t`${supplierName} should disclose the percentage of their energy covered by clean power.`;

  if (!disclosedCleanPower) {
    improvements.push(shouldDiscloseCleanPowerImprovement);
  }

  // Has no revenue
  if (!disclosedRevenue) {
    if (disclosedScopes123) {
      explanations.push(
        t`${supplierName} disclosed their total emissions, but not their revenue or revenue intensity. Revenue intensity is needed to create an emission factor because it lets you determine how much of ${supplierName}'s total emissions should be allocated to you.`
      );
    } else if (disclosedCEEActivities) {
      explanations.push(
        t`${supplierName} disclosed information about their operations, but not their revenue or revenue intensity. Revenue intensity is needed to create an emission factor because it lets you determine how much of ${supplierName}'s total emissions should be allocated to you.`
      );
    } else {
      explanations.push(
        t`${supplierName} did not disclose complete emissions and did not answer any questions about their operations.`
      );
    }

    return { explanations, improvements };
  }

  // Rest: has revenue

  // All scopes = can make an EF
  if (disclosedScopes123) {
    if (scope3CategoryBreakdown) {
      const explanation = thirdPartyVerified
        ? t`This disclosure's emission factor is based on ${supplierName}'s disclosed Scope 1, Scope 2, and upstream Scope 3 per unit of revenue (revenue intensity), which was verified by a third-party.`
        : t`This disclosure's emission factor is based on ${supplierName}'s disclosed Scope 1, Scope 2, and upstream Scope 3 per unit of revenue (revenue intensity).`;
      explanations.push(explanation);
    } else {
      explanations.push(
        t`This disclosure's emission factor is based on ${supplierName}'s disclosed Scope 1, Scope 2, and Scope 3 per unit of revenue (revenue intensity).`
      );
      improvements.push(
        t`${supplierName} should break out specific Scope 3 categories to ensure the emission factor doesn't double-count their downstream emissions.`
      );
    }

    if (!thirdPartyVerified) {
      improvements.push(shouldThirdPartyVerifyImprovement);
    }

    return { explanations, improvements };
  }

  // Revenue + CEE
  if (disclosedCEEActivities) {
    explanations.push(
      t`This disclosure's emission factor is based on industry average emissions for ${disclosedIndustry}, and adjusted based on ${supplierName}'s responses about their operations.`
    );

    if (!disclosedAnyEmissions) {
      improvements.push(shouldMeasureScopes123Improvement);
    } else if (!disclosedScope3) {
      improvements.push(
        t`${supplierName} should measure and disclose their upstream Scope 3 per unit of revenue (revenue intensity).`
      );
    }

    // Revenue + no CEE
  } else {
    // TODO: Branch based on disclosedCleanPower
    const explanation = disclosedCountries
      ? t`This disclosure's emission factor is based on industry average emissions for ${disclosedIndustry}, and adjusted based on ${supplierName}'s usage of clean power, and countries of operation.`
      : t`This disclosure's emission factor is based on industry average emissions for ${disclosedIndustry}, and adjusted based on ${supplierName}'s usage of clean power.`;
    explanations.push(explanation);
    improvements.push(shouldMeasureScopes123Improvement);
  }

  return { explanations, improvements };
}

export function getEmissionsSource(disclosure: {
  privateDisclosure: {
    privateDisclosureType: GQPrivateDisclosureType;
  } | null;
}): GQEmissionsSource {
  if (isNullish(disclosure.privateDisclosure)) {
    return GQEmissionsSource.ExternalReport;
  }

  const {
    privateDisclosure: { privateDisclosureType },
  } = disclosure;

  switch (privateDisclosureType) {
    case GQPrivateDisclosureType.Survey:
      return GQEmissionsSource.Survey;
    case GQPrivateDisclosureType.Estimate:
      return GQEmissionsSource.SurveyEstimate;
    case GQPrivateDisclosureType.WatershedMeasurement:
      return GQEmissionsSource.WatershedMeasurement;
    case GQPrivateDisclosureType.SurveyEstimate:
      return GQEmissionsSource.SurveyEstimate;
    // If it is a private CDP disclosure, the emissions are coming from an external report
    case GQPrivateDisclosureType.Cdp:
      return GQEmissionsSource.ExternalReport;
    case GQPrivateDisclosureType.ManualEntry:
      return GQEmissionsSource.ManualEntry;
    default:
      assertNever(privateDisclosureType);
  }
}

// This rank determines which disclosure to use for a supplier's emissions if
// there are multiple disclosures for the same year
export function getEmissionsSourceRank(
  historicalEmissions: Pick<SupplierHistoricalEmissionsFields, 'source'>
): number {
  const emissionsRank: Record<GQEmissionsSource, number> = {
    // Highest priority
    [GQEmissionsSource.ManualEntry]: -1,
    [GQEmissionsSource.WatershedMeasurement]: 0,
    [GQEmissionsSource.Survey]: 1,
    [GQEmissionsSource.ExternalReport]: 2,
    [GQEmissionsSource.SurveyEstimate]: 3,
    [GQEmissionsSource.CompanyNaicsCodeEstimate]: 4,
    [GQEmissionsSource.Cee]: 4,
    // Lowest priority
  };
  return emissionsRank[historicalEmissions.source];
}

export function getBestHistoricalEmissions<
  T extends SupplierHistoricalEmissionsFields,
>(d1: T, d2: T): T {
  // If we have more than one disclosure for the same reporting year,
  // tiebreak based on emissions source. For example, we prefer survey data
  // over external reports.
  const rank1 = getEmissionsSourceRank(d1);
  const rank2 = getEmissionsSourceRank(d2);
  if (rank1 < rank2) {
    return d1;
  }

  const units1 = d1.units;
  const units2 = d2.units;
  // Prefer intensity units over absolute units. This situation may occur
  // if a customer inputs emissions associated with a target.
  if (
    units1 === GQCompanyEmissionsUnits.Kgco2ePerDollar &&
    units2 === GQCompanyEmissionsUnits.Kgco2e
  ) {
    return d1;
  }

  // If we have more than one disclosure for the same reporting year and
  // source, tiebreak based on publishing year. We prefer newer data.
  const year1 = d1.publishingYear ?? 0;
  const year2 = d2.publishingYear ?? 0;
  if (year1 > year2) {
    return d1;
  }

  return d2;
}

export function dedupeHistoricalEmissions<
  T extends SupplierHistoricalEmissionsFields,
>(historicalEmissions: Array<T>): Array<T> {
  const bestHistoricalEmissionsRecordByYear: Record<number, T> = {};

  historicalEmissions.forEach((historicalEmission) => {
    const prev =
      bestHistoricalEmissionsRecordByYear[historicalEmission.reportingYear];
    if (isNullish(prev)) {
      bestHistoricalEmissionsRecordByYear[historicalEmission.reportingYear] =
        historicalEmission;
      return;
    }

    bestHistoricalEmissionsRecordByYear[historicalEmission.reportingYear] =
      getBestHistoricalEmissions(prev, historicalEmission);
  });

  return sortBy(
    Object.values(bestHistoricalEmissionsRecordByYear),
    (historicalEmission) => historicalEmission.reportingYear
  );
}

export function dedupeAndFilterHistoricalEmissions<
  T extends SupplierHistoricalEmissionsFields,
>(historicalEmissions: Array<T>): Array<T> {
  // CHOOSING HISTORICAL EMISSIONS TO DISPLAY
  //
  // alright gang, heres the idea. the problem is that disclosures can now have varying units...
  // but we can't show different units on the same chart. So, we need to choose a unit to stick
  // with. We'll choose the unit of the best, most recent historical emission, and filter out any
  // other historical emissions that don't match that unit.

  // 1. Dedupe original list of historical emissions
  // so that we can find the best, most recent emissions
  const dedupedHistoricalEmissions =
    dedupeHistoricalEmissions(historicalEmissions);
  const latestHistoricalEmission = last(dedupedHistoricalEmissions);

  // 2. Filter out original historical emissions that don't match
  // the unit of the best, most recent historical emission
  const filteredHistoricalEmissions = historicalEmissions.filter(
    (emission) =>
      isNullish(latestHistoricalEmission) ||
      emission.units === latestHistoricalEmission.units
  );

  // 3. Dedupe the filtered list of historical emissions
  // so that we don't miss out on any emissions that were filtered out
  // from the step 1 dedupe due to prioritization of emissions sources
  const dedupedFilteredHistoricalEmissions = dedupeHistoricalEmissions(
    filteredHistoricalEmissions
  );

  return dedupedFilteredHistoricalEmissions;
}

export function filterHistoricalEmissionsToAbsoluteUnits<
  T extends SupplierHistoricalEmissionsFields,
>(historicalEmissions: Array<T>): Array<T> {
  return historicalEmissions.filter(
    (emission) => emission.units === GQCompanyEmissionsUnits.Kgco2e
  );
}

export enum EmissionSource {
  // private disclosures
  WatershedSurvey = 'Watershed survey',
  CdpPrivateData = 'CDP private data',

  // public disclosures
  CdpPublicData = 'CDP public data',
  EsgClimateReport = 'ESG climate report',
  BCorp = 'B Corp report',
  Carb = 'CARB report',
  Csrd = 'CSRD report',
  Djsi = 'DJSI report',
  Edci = 'EDCI report',
  Edgar = 'EDGAR report',
  Issb = 'ISSB report',
  Impact = 'Impact report',
  Msci = 'MSCI report',
  Marketing = 'Marketing report',
  Other = 'Other report',
  Sasb = 'SASB report',
  Sb253 = 'SB253 report',
  Sbt = 'SBT report',
  Sec = 'SEC report',
  Secr = 'SECR report',
  Sfdr = 'SFDR report',
  Sustainalytics = 'Sustainalytics report',
  Tcfd = 'TCFD report',
  Unfccc = 'UNFCCC report',
  UkTender = 'UK Tender report',
}

export function getEmissionSource(disclosure: {
  privateDisclosure: {
    privateDisclosureType: GQPrivateDisclosureType;
  } | null;
  publicDisclosure: {
    reportType: GQExternalReportType;
  } | null;
}): EmissionSource | null {
  if (disclosure.privateDisclosure) {
    if (
      disclosure.privateDisclosure.privateDisclosureType ===
      GQPrivateDisclosureType.Survey
    ) {
      return EmissionSource.WatershedSurvey;
    }
    if (
      disclosure.privateDisclosure.privateDisclosureType ===
      GQPrivateDisclosureType.Cdp
    ) {
      return EmissionSource.CdpPrivateData;
    }
  }
  if (disclosure.publicDisclosure) {
    const reportType = disclosure.publicDisclosure.reportType;
    // Map each report type to corresponding EmissionSource
    switch (reportType) {
      case GQExternalReportType.Esg:
        return EmissionSource.EsgClimateReport;
      case GQExternalReportType.Cdp:
        return EmissionSource.CdpPublicData;
      case GQExternalReportType.BCorp:
        return EmissionSource.BCorp;
      case GQExternalReportType.Carb:
        return EmissionSource.Carb;
      case GQExternalReportType.Csrd:
        return EmissionSource.Csrd;
      case GQExternalReportType.Djsi:
        return EmissionSource.Djsi;
      case GQExternalReportType.Edci:
        return EmissionSource.Edci;
      case GQExternalReportType.Edgar:
        return EmissionSource.Edgar;
      case GQExternalReportType.Issb:
        return EmissionSource.Issb;
      case GQExternalReportType.Impact:
        return EmissionSource.Impact;
      case GQExternalReportType.Msci:
        return EmissionSource.Msci;
      case GQExternalReportType.Marketing:
        return EmissionSource.Marketing;
      case GQExternalReportType.Other:
        return EmissionSource.Other;
      case GQExternalReportType.Sasb:
        return EmissionSource.Sasb;
      case GQExternalReportType.Sb253:
        return EmissionSource.Sb253;
      case GQExternalReportType.Sbt:
        return EmissionSource.Sbt;
      case GQExternalReportType.Sec:
        return EmissionSource.Sec;
      case GQExternalReportType.Secr:
        return EmissionSource.Secr;
      case GQExternalReportType.Sfdr:
        return EmissionSource.Sfdr;
      case GQExternalReportType.Sustainalytics:
        return EmissionSource.Sustainalytics;
      case GQExternalReportType.Tcfd:
        return EmissionSource.Tcfd;
      case GQExternalReportType.Unfccc:
        return EmissionSource.Unfccc;
      case GQExternalReportType.UkTender:
        return EmissionSource.UkTender;
      default:
        return EmissionSource.Other;
    }
  }
  return null;
}

export type HistoricalEmission = SupplierHistoricalEmissionsFields & {
  disclosureSource: EmissionSource | null;
  privateDisclosureId: string | undefined;
  publicDisclosureId: string | undefined;
};

export function getNewEmissionsSourceRank(
  historicalEmission: Pick<HistoricalEmission, 'disclosureSource'>
): number {
  // Preference is Survey > CDP Private > CDP Public > ESG Public
  if (historicalEmission.disclosureSource === EmissionSource.WatershedSurvey) {
    return 0;
  } else if (
    historicalEmission.disclosureSource === EmissionSource.CdpPrivateData
  ) {
    return 1;
  } else if (
    historicalEmission.disclosureSource === EmissionSource.CdpPublicData
  ) {
    return 2;
  } else if (
    historicalEmission.disclosureSource === EmissionSource.EsgClimateReport
  ) {
    return 3;
  }
  return 4;
}

/**
 * Given a list of historical emissions and a list of report types,
 * this function will filter the list to only include the report types,
 * and then apply the deduping logic based on priority
 */

export function getRelevantHistoricalEmissions(
  historicalEmissions: Array<HistoricalEmission>,
  disclosureSources: Array<EmissionSource>
): Array<HistoricalEmission> {
  const filteredHistoricalEmissions = historicalEmissions.filter((emission) =>
    emission.disclosureSource
      ? disclosureSources.includes(emission.disclosureSource)
      : false
  );
  const groupedByReportingYear = groupBy(
    filteredHistoricalEmissions,
    (emission) => emission.reportingYear
  );

  // for each year, we use the prioritization logic to pick the best emissions
  const dedupedHistoricalEmissions = Object.values(groupedByReportingYear).map(
    (emissions) => {
      const sortedEmissions = sortBy(emissions, (emission) =>
        getNewEmissionsSourceRank(emission)
      );
      return sortedEmissions[0];
    }
  );
  return sortBy(
    dedupedHistoricalEmissions,
    (emission) => emission.reportingYear
  );
}

export const SCOPES = ['scope1', 'scope2', 'scope3'] as const;
export type SCOPE = (typeof SCOPES)[number];

export function getHistoricalEmissionsByScope(
  emission: SupplierHistoricalEmissionsFields,
  scope: SCOPE,
  useUpstreamScope3?: boolean
): number | null {
  switch (scope) {
    case 'scope1':
      return emission.scope1;
    case 'scope2':
      return emission.scope2;
    case 'scope3':
      return useUpstreamScope3
        ? (EmissionsYear.getUpstreamScope3Emissions(emission) ??
            emission.scope3)
        : emission.scope3;
    default:
      assertNever(scope);
  }
}

export type GhgScopeOrCategory =
  | (typeof ALL_GHG_IDS)[number]
  | 'scope 1'
  | 'scope 2'
  | 'scope 3';
type HistoricalYearField = keyof SupplierHistoricalEmissionsFields;
export const FILTER_VALUE_TO_HISTORICAL_YEAR_FIELD: Record<
  GhgScopeOrCategory,
  HistoricalYearField
> = {
  'scope 1': 'scope1',
  'scope 2': 'scope2',
  'scope 3': 'scope3',
  [SCOPE_1_CATEGORY_ID]: 'scope1',
  [SCOPE_2_CATEGORY_ID]: 'scope2',
  [SCOPE_3_1_ID]: 'scope301',
  [SCOPE_3_2_ID]: 'scope302',
  [SCOPE_3_3_ID]: 'scope303',
  [SCOPE_3_4_ID]: 'scope304',
  [SCOPE_3_5_ID]: 'scope305',
  [SCOPE_3_6_ID]: 'scope306',
  [SCOPE_3_7_ID]: 'scope307',
  [SCOPE_3_8_ID]: 'scope308',
  [SCOPE_3_9_ID]: 'scope309',
  [SCOPE_3_10_ID]: 'scope310',
  [SCOPE_3_11_ID]: 'scope311',
  [SCOPE_3_12_ID]: 'scope312',
  [SCOPE_3_13_ID]: 'scope313',
  [SCOPE_3_14_ID]: 'scope314',
  [SCOPE_3_15_ID]: 'scope315',
};

export function getSupplierHistoricalEmissionsMatchingFilter(
  emission: GQSupplierHistoricalEmissions,
  filter: SupplierDisclosureTargetField['filters']
): number | null {
  // If any filter is on GhgCategoryId, but we don't have category emissions, return null
  if (
    filter.expressions.some(
      (expr) => expr.field === GQFilterFieldLegacy.GhgCategoryId
    ) &&
    !EmissionsYear.hasScope3CategoryEmissions(emission)
  ) {
    return null;
  }

  // Sum up the emissions for the matching fields
  const resultFields = getMatchingHistoricalYearFieldsForFilter(filter);
  let result = 0;
  resultFields.forEach((f) => {
    const value = emission[f] as number;
    if (!isNullish(value)) {
      result += value;
    }
  });
  return result;
}

export function areTargetFiltersOverlapping(
  filtersA: DisclosureTargetFilters,
  filtersB: DisclosureTargetFilters
): boolean {
  // This filter check only supports GhgScope and GhgCategoryId
  invariant(
    filtersA.expressions.every(
      (expr) =>
        expr.field === GQFilterFieldLegacy.GhgCategoryId ||
        expr.field === GQFilterFieldLegacy.GhgScope
    ),
    'Unsupported filter expression in overlap check'
  );
  invariant(
    filtersB.expressions.every(
      (expr) =>
        expr.field === GQFilterFieldLegacy.GhgCategoryId ||
        expr.field === GQFilterFieldLegacy.GhgScope
    ),
    'Unsupported filter expression in overlap check'
  );

  // Overlapping if any field matches directly
  const fieldsA = getMatchingHistoricalYearFieldsForFilter(filtersA);
  const fieldsASet = new Set(fieldsA);
  const fieldsB = getMatchingHistoricalYearFieldsForFilter(filtersB);
  if (fieldsB.some((field) => fieldsASet.has(field))) {
    return true;
  }

  // Overlapping if A has scope3 and B has scope 3x. We can just check
  // startsWith since categories and scope both start with "scope3"
  const hasScope3A = fieldsA.some((field) => field.startsWith('scope3'));
  const hasScope3B = fieldsB.some((field) => field.startsWith('scope3'));
  if (hasScope3A && hasScope3B) {
    return true;
  }

  return false;
}

export function getTargetEndYear(
  target: Pick<SupplierDisclosureTargetField, 'baseYear' | 'emissionsTarget'>
): YearMonth {
  return YM.plus(
    target.baseYear,
    target.emissionsTarget.values.length,
    target.emissionsTarget.frequency === 'Yearly' ? 'year' : 'month'
  );
}

export function getMatchingHistoricalYearFieldsForFilter(
  filter: DisclosureTargetFilters
): Array<HistoricalYearField> {
  // We don't support filters here other than GhgCategoryId or GhgScope
  if (
    filter.expressions.some(
      (expr) =>
        expr.field !== GQFilterFieldLegacy.GhgCategoryId &&
        expr.field !== GQFilterFieldLegacy.GhgScope
    )
  ) {
    console.warn(
      `[DisclosureUtils] Unsupported filter on disclosure target`,
      filter
    );
    return [];
  }

  if (filter.expressions.length > 2) {
    console.warn(
      `[DisclosureUtils] Unsupported filter on disclosure target`,
      filter
    );
    return [];
  }

  // We only support one two-expression case: scope 3 + some category filter
  if (filter.expressions.length === 2) {
    const scope3Filter = filter.expressions.find(
      (expr) => expr.field === GQFilterFieldLegacy.GhgScope
    );
    const otherFilter = filter.expressions.find(
      (expr) => expr.field !== GQFilterFieldLegacy.GhgScope
    );
    if (
      filter.conjunction !== FilterConjunction.andConjunction ||
      !scope3Filter?.value.includes('scope 3') ||
      otherFilter?.field !== GQFilterFieldLegacy.GhgCategoryId
    ) {
      console.warn(
        `[DisclosureUtils] Unsupported filter on disclosure target`,
        filter
      );
      return [];
    }
    return otherFilter.value.map((v) =>
      must(
        FILTER_VALUE_TO_HISTORICAL_YEAR_FIELD[v as GhgScopeOrCategory],
        'Unexpected filter value: ' + v
      )
    );
  }

  // Figure out which historical year fields match the filter
  let resultFields: Array<HistoricalYearField>;
  if (filter.conjunction === FilterConjunction.andConjunction) {
    resultFields = uniq(Object.values(FILTER_VALUE_TO_HISTORICAL_YEAR_FIELD));
  } else {
    resultFields = [];
  }

  filter.expressions.forEach((expr) => {
    const matchingFields = expr.value.map((v) =>
      must(
        FILTER_VALUE_TO_HISTORICAL_YEAR_FIELD[v as GhgScopeOrCategory],
        'Unexpected filter value: ' + v
      )
    );
    if (filter.conjunction === FilterConjunction.andConjunction) {
      resultFields = resultFields.filter((f) =>
        matchingFields.some((mf) => mf === f)
      );
    } else {
      resultFields = resultFields.concat(matchingFields);
    }
  });
  return resultFields;
}

export const getTargetScopes = (
  filter: DisclosureTargetFilters
): Array<string> => {
  const scopeExpression = filter.expressions.find(
    (expression) =>
      expression.field === GQFilterFieldLegacy.GhgScope &&
      expression.operator === GQFilterOperator.In
  );
  if (isNullish(scopeExpression)) {
    return [];
  }
  const targetScopes = scopeExpression.value.map(
    (filterValue) =>
      FILTER_VALUE_TO_HISTORICAL_YEAR_FIELD[filterValue as GhgScopeOrCategory]
  );
  return targetScopes;
};

export function getSupplierHistoricalEmissions(
  emission: SupplierHistoricalEmissionsFields,
  useUpstreamScope3?: boolean
): number | null {
  const scope1 = emission.scope1;
  const scope2 = emission.scope2;
  const scope3 = useUpstreamScope3
    ? (EmissionsYear.getUpstreamScope3Emissions(emission) ?? emission.scope3)
    : emission.scope3;
  if (isNullish(scope1) || isNullish(scope2) || isNullish(scope3)) {
    return null;
  }
  return scope1 + scope2 + scope3;
}

export function getUnitLabel(
  units: GQCompanyEmissionsUnits,
  currency?: CurrencyCode | null
): string {
  // Pull out the logic for handling revenue intensity to customize with kgCO₂e / $1 instead of tCO₂e / $1M
  return units === GQCompanyEmissionsUnits.Kgco2ePerDollar
    ? `kgCO₂e / ${getCurrencySymbol(currency ?? 'USD')}`
    : getUnitTooltipForGQAggregateKind({
        aggregateKind: GQAggregateKind.Total,
        currency,
      });
}

export function getUnitTypeLabel(
  units: GQCompanyEmissionsUnits
): MessageDescriptor {
  switch (units) {
    case GQCompanyEmissionsUnits.Kgco2e:
      return msg({ message: 'Gross emissions' });
    case GQCompanyEmissionsUnits.AllocatedKgco2e:
      return msg({ message: 'Allocated emissions' });
    case GQCompanyEmissionsUnits.Kgco2ePerDollar:
      return msg({ message: 'Revenue intensity' });
    default:
      assertNever(units);
  }
}

export function unitConversionFactorToTonsIfKgco2e(
  units: GQCompanyEmissionsUnits
): number {
  switch (units) {
    case GQCompanyEmissionsUnits.Kgco2e:
      return 1 / 1000;
    case GQCompanyEmissionsUnits.AllocatedKgco2e:
      return 1 / 1000;
    case GQCompanyEmissionsUnits.Kgco2ePerDollar:
      return 1; // Leave as kgCO2e / $1
    default:
      assertNever(units);
  }
}

export function formatEmissionsDatum({
  value,
  units,
  locale,
}: {
  value: number;
  units: GQCompanyEmissionsUnits;
  locale?: SupportedLocale;
}): string {
  switch (units) {
    case GQCompanyEmissionsUnits.Kgco2e:
    case GQCompanyEmissionsUnits.AllocatedKgco2e:
    case GQCompanyEmissionsUnits.Kgco2ePerDollar:
      return formatNumberMagnitude(value, {
        locale,
        maximumFractionDigitsForSmallNumber: value >= 0.001 ? 3 : 6,
      });
    default:
      assertNever(units);
  }
}

export function kgco2PerDollarToTonCo2PerMillion(
  value: number | null
): number | null {
  if (value) {
    // kgCO₂e/$1 to tCO₂e/$1M conversion unit
    return value * 1000;
  }
  return null;
}

export function getEmissionsScopeRatio(
  emission: SupplierHistoricalEmissionsFields,
  scope: SCOPE
): number | null {
  switch (scope) {
    case 'scope1':
      return emission.scope1Ratio;
    case 'scope2':
      return emission.scope2Ratio;
    case 'scope3':
      return emission.scope3Ratio;
    default:
      assertNever(scope);
  }
}

function getPublishingYear(
  disclosure: SupplierForReductionsForecast['disclosures'][number]
): number | null {
  const source = getEmissionsSource(disclosure);
  switch (source) {
    case GQEmissionsSource.Survey:
    case GQEmissionsSource.SurveyEstimate:
      return disclosure.privateDisclosure?.createdAt.getFullYear() ?? null;
    case GQEmissionsSource.ExternalReport:
      return disclosure.publicDisclosure?.publishingYear ?? null;
    case GQEmissionsSource.CompanyNaicsCodeEstimate:
    case GQEmissionsSource.WatershedMeasurement:
    case GQEmissionsSource.Cee:
    case GQEmissionsSource.ManualEntry:
      return null;
    default:
      assertNever(source);
  }
}

export function disclosureToGQSupplierHistoricalEmissions(
  disclosure: SupplierForReductionsForecast['disclosures'][number]
): Array<SupplierHistoricalEmissionsFields> {
  return (
    disclosure.historicalEmissionsYears?.map((historicalEmissionsYear) => {
      const allScopeEmissionsAndRatios = getAllScopeEmissionsAndRatios({
        ...historicalEmissionsYear,
        scope2: null,
      });

      return {
        ...allScopeEmissionsAndRatios,
        reportingYear: historicalEmissionsYear.reportingYear,
        publishingYear: getPublishingYear(disclosure),
        source: getEmissionsSource(disclosure),
        publicUrl: disclosure.publicDisclosure?.publicUrl ?? null,
        surveyId: disclosure.privateDisclosure?.surveyId ?? null,
        revenue: historicalEmissionsYear.revenue,
        revenueCurrency: historicalEmissionsYear.revenueCurrency,
        revenueUsd: historicalEmissionsYear.revenueUsd,
        units: historicalEmissionsYear.units,
        expenseCategory: historicalEmissionsYear.expenseCategory,
      };
    }) ?? []
  );
}

// we expect that supplier EFs are calculated using
// scope 1, 2, 3.1-3.8, and 3.16 (Other upstream) emissions
export function calculateSupplierEF(
  historicalEmission: {
    scope1: number | null;
    scope2: number | null;
    scope3: number | null;
    scope301: number | null;
    scope302: number | null;
    scope303: number | null;
    scope304: number | null;
    scope305: number | null;
    scope306: number | null;
    scope307: number | null;
    scope308: number | null;
    scope316: number | null;
    units: GQCompanyEmissionsUnits;
    revenue: number | null;
  } | null,
  totalSpend?: number | null
): number | null {
  if (
    isNullish(historicalEmission) ||
    isNullish(historicalEmission.scope1) ||
    isNullish(historicalEmission.scope2) ||
    isNullish(historicalEmission.scope3)
  ) {
    return null;
  }

  const scope3ForEF =
    EmissionsYear.getUpstreamScope3Emissions(historicalEmission) ??
    historicalEmission.scope3;
  const emissionsForEf =
    historicalEmission.scope1 + historicalEmission.scope2 + scope3ForEF;

  if (historicalEmission.units === GQCompanyEmissionsUnits.Kgco2e) {
    if (isNullish(historicalEmission.revenue)) {
      return null;
    }

    return emissionsForEf / historicalEmission.revenue;
  } else if (
    historicalEmission.units === GQCompanyEmissionsUnits.AllocatedKgco2e
  ) {
    if (isNullish(totalSpend)) {
      return null;
    }

    return emissionsForEf / totalSpend;
  } else if (
    historicalEmission.units === GQCompanyEmissionsUnits.Kgco2ePerDollar
  ) {
    // Else the units are Kgco2ePerDollar
    return emissionsForEf;
  } else {
    assertNever(historicalEmission.units);
  }
}

// TODO: Can we just make these the same enum?
export const COMPANY_EMISSIONS_UNITS_TO_DISCLOSURE_TARGET_INTENSITY_TYPE: Record<
  GQCompanyEmissionsUnits,
  DisclosureTargetIntensityType
> = {
  [GQCompanyEmissionsUnits.Kgco2e]: DisclosureTargetIntensityType.Absolute,
  [GQCompanyEmissionsUnits.AllocatedKgco2e]:
    DisclosureTargetIntensityType.Absolute, // is this right?
  [GQCompanyEmissionsUnits.Kgco2ePerDollar]:
    DisclosureTargetIntensityType.Revenue,
};

export function shouldShowDisclosureQualityForEngagementTask({
  engagementTask,
  engagementTaskAnswers,
}: {
  engagementTask: {
    reportingYear: number | null;
  };
  engagementTaskAnswers: Array<SurveyAnswer>;
}): boolean {
  // When suppliers attach a report, we manually extract the emissions
  // data from it. We don't want to show a quality score until we've attempted
  // to extract that emissions data.
  const hasEmissionsData = surveyAnswersHaveSomeEmissionsData(
    engagementTaskAnswers
  );

  return hasEmissionsData && isNotNullish(engagementTask.reportingYear);
}

export function isSurveyDisclosure(
  disclosureType: GQPrivateDisclosureType | undefined | null
): boolean {
  return (
    isNotNullish(disclosureType) &&
    (
      [
        GQPrivateDisclosureType.Survey,
        GQPrivateDisclosureType.SurveyEstimate,
        GQPrivateDisclosureType.Estimate,
      ] as Array<GQPrivateDisclosureType>
    ).includes(disclosureType)
  );
}

export function getDateDetailsForDisclosure(disclosure: {
  historicalEmissionsYears: Array<{ reportingYear: number }> | null;
  publicDisclosure: {
    reportType: GQExternalReportType;
    publishingYear: number;
    cdpVendorData: {
      reportingYear: number;
    } | null;
  } | null;
  privateDisclosure: {
    createdAt: Date;
  } | null;
}): { displayDate: string | number; date: DateTime } {
  const sortedReportingYears = orderBy(
    uniq(
      disclosure.historicalEmissionsYears?.map((year) => year.reportingYear) ??
        []
    ),
    [],
    'desc'
  );

  const mostRecentReportingYearAsDate = sortedReportingYears[0]
    ? DateTimeUtils.fromYearAndMonth(sortedReportingYears[0], 1)
    : null;
  const displayReportingYears = isEmpty(sortedReportingYears)
    ? null
    : // TODO: i18n (please resolve or remove this TODO line if legit)
      // eslint-disable-next-line @watershed/no-join-commas
      sortedReportingYears.join(', ');

  const cdpPublicReportingYear =
    disclosure.publicDisclosure?.reportType === 'CDP'
      ? disclosure.publicDisclosure?.cdpVendorData?.reportingYear
      : null;
  const cdpPublicReportingYearAsDate = cdpPublicReportingYear
    ? DateTimeUtils.fromYearAndMonth(cdpPublicReportingYear, 1)
    : null;

  const defaultDate =
    DateTimeUtils.fromMaybeJSDate(disclosure.privateDisclosure?.createdAt) ??
    DateTimeUtils.fromYearAndMonth(
      must(
        disclosure.publicDisclosure?.publishingYear,
        'Found a disclosure with no public or private disclosure'
      ),
      1
    );

  return {
    displayDate:
      displayReportingYears ?? cdpPublicReportingYear ?? defaultDate?.year,
    date:
      mostRecentReportingYearAsDate ??
      cdpPublicReportingYearAsDate ??
      defaultDate,
  };
}

export function getDisclosureType(disclosure: {
  publicDisclosure: {
    reportType: GQExternalReportType;
  } | null;
  privateDisclosure: {
    privateDisclosureType: GQPrivateDisclosureType;
  } | null;
}): GQExternalReportType | GQPrivateDisclosureType | null {
  return (
    disclosure.publicDisclosure?.reportType ??
    disclosure.privateDisclosure?.privateDisclosureType ??
    null
  );
}

export function getDisclosureName(
  disclosure: {
    publicDisclosure: {
      reportType: GQExternalReportType;
    } | null;
    privateDisclosure: {
      privateDisclosureType: GQPrivateDisclosureType;
      orgName: string | null;
    } | null;
  },
  displayDate: string | number,
  parentCompanyName: string | null
): string {
  const baseName = isNotNullish(disclosure.publicDisclosure)
    ? `${parentCompanyName ? `${parentCompanyName}'s` : ''} ${
        disclosure.publicDisclosure.reportType
      } climate report`
    : isSurveyDisclosure(disclosure.privateDisclosure?.privateDisclosureType)
      ? 'Watershed survey response'
      : `${disclosure.privateDisclosure?.orgName ?? 'Your'} data`;
  return `${baseName} ${displayDate}`;
}
